import React from 'react';
import {createRoot} from 'react-dom/client';
import JshModule from '@core/scripts/helper/jsh-module';
import RecommendationSliderDeSy from "../../components/RecommendationSliderDeSy.jsx";

const {moduleScope, config} = JshModule('personalization/recommendations-desy');

const {pageViewId, ajaxSwiperData, max_width} = config;
const {paramString, dataUrl} = ajaxSwiperData;

const paramStringWithPageViewId = pageViewId ? paramString + '&pageViewId=' + pageViewId : paramString;

// TODO: Get translation or something
const productCardTexts = {
    pc_color_descriptor: "Farbe",
    pc_size_descriptor: "Gr��e",
    pc_please_select: "Ausw�hlen",
    pc_variant: "Variante",
    pc_soldout: "Ausverkauft"
}

createRoot(moduleScope).render(
    <>
        <RecommendationSliderDeSy
               dataUrl={dataUrl}
               paramString={paramStringWithPageViewId}
               productCardTexts={productCardTexts}
               max_width={max_width}
        ></RecommendationSliderDeSy>
    </>
);
